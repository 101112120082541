/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/Metropolis-Regular.otf")
}
body{
  font-family: 'Metropolis';
  letter-spacing: 0.5px!important;
}


.ant-card-body {
  padding: 10px;
}

      .ant-drawer-close {
        position: absolute;
        top: 7px;
        right: 0;
        z-index: 10;
        display: block;
        padding: 20px;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        font-style: normal;
        line-height: 1;
        text-align: center;
        text-transform: none;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color 0.3s;
        text-rendering: auto;
    }  
    .ant-drawer-header {
      position: relative;
      padding: 5px 24px;
      color: rgba(0, 0, 0, 0.85);
      background: #19baba;
      border-bottom: 1px solid #19baba;
      border-radius: 2px 2px 0 0;
  }
  .ant-drawer-footer {
    flex-shrink: 0;
    padding: 16px 10px;
    border-top: 1px solid #d4c3c3;
    text-align: center;
}
.ant-modal-close-x {
  display: block;
    width: 22px !important;
    height: 22px !important;
    font-size: 12px;
    font-style: normal;
    line-height: 21px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    margin-top: 15px;
    margin-right: 10px;
    border: 1px solid #ff0a0a;
    border-radius: 50px !important;
    color: #ff0a0a;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 2px 2px 0 0;
}
p.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e6f7ff;
  width: 100px;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 50px;
  padding: 10px 11px;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #19baba;
  border:1px solid #19baba;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  border:1px solid #19baba;
  background-color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #19baba;
}
.ant-card-body {
  padding: 15px;
}
.ant-modal-close-x {
  display: block;
    width: 22px !important;
    height: 22px !important;
    font-size: 12px;
    font-style: normal;
    line-height: 21px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    margin-top: 15px;
    margin-right: 10px;
    border: 1px solid #ff0a0a;
    border-radius: 50px !important;
    color: #ff0a0a;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #fff;
  border-radius: 2px 2px 0 0;
}

element.style {
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
}
.ant-select-item-option {
    display: flex;
}
::-webkit-scrollbar {
  width: 12px;
}
}

 
.scale-up-hor-right {
	-webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-30 11:59:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-hor-right
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}


.scale-up-hor-left {
	-webkit-animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-30 12:20:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-hor-left
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
